import React from 'react';
import styled from 'styled-components';
import { BlogPostCard } from './BlogPost';
import blogPosts from '../blogPosts.json';

const BlogOverviewContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 30px;
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`;

function BlogOverview() {
  return (
    <BlogOverviewContainer>
      <Title>Blog Posts</Title>
      <PostsGrid>
        {blogPosts.map((post) => (
          <BlogPostCard
            key={post.slug}
            slug={post.slug}
            title={post.title}
            excerpt={post.excerpt}
            image={post.image}
            date={post.date}
          />
        ))}
      </PostsGrid>
    </BlogOverviewContainer>
  );
}

export default BlogOverview;
