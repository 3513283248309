import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BlogPostCard } from './BlogPost';
import blogPosts from '../blogPosts.json';

const HomeContainer = styled.div`
  padding: 0 0 50px;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 30px 0;
  }
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const SectionDivider = styled.hr`
  border: none;
  border-top: 1px solid ${props => props.theme.colors.border};
  margin: 60px 0;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 20px;
  color: ${props => props.theme.colors.primary};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 28px;
  }
`;

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
`;

// const Card = styled.div`
//   background-color: ${props => props.theme.colors.cardBackground};
//   padding: 20px;
//   border-radius: 8px;
//   border: 1px solid ${props => props.theme.colors.border};
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   display: flex;
//   flex-direction: column;
//   height: 100%;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
//   }
// `;

// const CardTitle = styled.h2`
//   font-size: 20px;
//   font-weight: ${props => props.theme.fontWeights.semibold};
//   color: ${props => props.theme.colors.primary};
//   margin-bottom: 10px;
// `;

// const CardDivider = styled.hr`
//   border: none;
//   border-top: 1px solid ${props => props.theme.colors.border};
//   margin: 10px 0;
// `;

// const CardDescription = styled.p`
//   font-size: 16px;
//   color: ${props => props.theme.colors.text};
//   flex-grow: 1;
//   margin-bottom: 15px;
// `;

// const BookNowButton = styled.button`
//   background-color: transparent;
//   color: ${props => props.theme.colors.accent};
//   border: 1px solid ${props => props.theme.colors.accent};
//   padding: 8px 12px;
//   border-radius: 4px;
//   font-size: 14px;
//   font-weight: ${props => props.theme.fontWeights.semibold};
//   cursor: pointer;
//   transition: background-color 0.3s ease, color 0.3s ease;

//   &:hover {
//     background-color: ${props => props.theme.colors.accent};
//     color: white;
//   }
// `;

// Add these new styled components for the About section
const AboutContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const AboutImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 200px;
    height: 200px;
  }
`;

const AboutContent = styled.div`
  flex: 1;
`;

const AboutText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: ${props => props.theme.colors.text};
  margin-bottom: 20px;
`;

const LearnMoreButton = styled(Link)`
  display: inline-block;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: ${props => props.theme.fontWeights.semibold};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.accent};
  }
`;

// Add these new styled components for the hero section
const HeroSection = styled.section`
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/images/background.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.15);
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 0 20px;
  max-width: 800px; // Added to constrain content width
`;

const HeroTitle = styled.h1`
  font-size: 36px; // Reduced from 48px
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 15px; // Reduced from 20px
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 28px; // Reduced from 36px
  }
`;

const HeroSubtitle = styled.p`
  font-size: 24px;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 18px;
  }
`;

const HeroShadowLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), transparent);
`;

const HeroShadowRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  background: linear-gradient(to left, rgba(0, 0, 0, 1), transparent);
`;

function Home() {
  // Get the latest 3 blog posts
  const latestPosts = blogPosts.slice(0, 3);

  return (
    <HomeContainer>
      <HeroSection>
        <HeroBackground />
        <HeroShadowLeft />
        <HeroShadowRight />
        <HeroContent>
          <HeroTitle>Expert SRE & DevOps Solutions</HeroTitle>
          <HeroSubtitle>Optimize Your Infrastructure and Accelerate Your Development</HeroSubtitle>
        </HeroContent>
      </HeroSection>

      {/* <Section>
        <ServicesContainer>
          <Card>
            <CardTitle>Site Reliability Engineering</CardTitle>
            <CardDivider />
            <CardDescription>Ensure your systems are reliable, scalable, and efficient with our SRE expertise.</CardDescription>
            <BookNowButton>Book Now</BookNowButton>
          </Card>
          <Card>
            <CardTitle>DevOps Implementation</CardTitle>
            <CardDivider />
            <CardDescription>Streamline your development and operations with our proven DevOps methodologies.</CardDescription>
            <BookNowButton>Book Now</BookNowButton>
          </Card>
          <Card>
            <CardTitle>Cloud Infrastructure</CardTitle>
            <CardDivider />
            <CardDescription>Optimize your cloud infrastructure for performance, security, and cost-effectiveness.</CardDescription>
            <BookNowButton>Book Now</BookNowButton>
          </Card>
        </ServicesContainer>
      </Section>

      <SectionDivider /> */}

      <Section>
        <Title>Latest Posts</Title>
        <ServicesContainer>
          {latestPosts.map((post) => (
            <BlogPostCard
              key={post.slug}
              slug={post.slug}
              title={post.title}
              excerpt={post.excerpt}
              image={post.image}
              date={post.date}
            />
          ))}
        </ServicesContainer>
      </Section>

      <SectionDivider />

      <Section>
        <Title>About Me</Title>
        <AboutContainer>
          <AboutImage src="/images/profile.png" alt="Christian Beneke - Site Reliability Engineer" />
          <AboutContent>
            <AboutText>
              I am Christian Beneke, a seasoned SRE and DevOps engineer with years of experience in the industry.
              I help businesses optimize their IT operations, improve system reliability, and
              accelerate their development processes.
            </AboutText>
            <AboutText>
              My expertise spans a wide range of technologies and methodologies, including
              cloud infrastructure, continuous integration and deployment, containerization,
              and monitoring systems.
            </AboutText>
            <LearnMoreButton to="/cv">Learn More</LearnMoreButton>
          </AboutContent>
        </AboutContainer>
      </Section>
    </HomeContainer>
  );
}

export default Home;
