import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const FooterContainer = styled.div`
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterText = styled.p`
  font-size: 14px;
  margin: 0;
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterText>© {new Date().getFullYear()} - Christian Beneke. All rights reserved.</FooterText>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;