const theme = {
    colors: {
      background: '#F7FAFC',     // Light background for the page
      cardBackground: '#FFFFFF', // White background for cards
      primary: '#2D3748',        // Dark color for main text
      secondary: '#4A5568',      // Slightly lighter for subtitles
      accent: '#4A90E2',         // Accent color: soft blue
      accentDark: '#3A7BC8',     // Darker shade for button hover
      text: '#1A202C',           // Dark text color
      border: '#E2E8F0',         // Light border color
      highlight: '#F6AD55',      // Softer orange highlight
    },
    fonts: {
      main: "'Inter', sans-serif",
    },
    fontWeights: {
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    breakpoints: {
      mobile: '480px',
      tablet: '768px',
      desktop: '1024px',
    }
};

export default theme;