import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import blogPosts from '../blogPosts.json';

// Styled components for BlogPostCard
const Card = styled(Link)`
  background-color: ${props => props.theme.colors.cardBackground};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.border};
  overflow: hidden; // This ensures the image doesn't overflow the rounded corners
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: inherit;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 200px; // You can adjust this height as needed
  background-color: ${props => props.theme.colors.secondary};
  background-image: ${props => props.image ? `url(${props.image})` : 'none'};
  background-size: cover;
  background-position: center;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeights.semibold};
  color: ${props => props.theme.colors.primary};
  margin-bottom: 10px;
`;

const Excerpt = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  flex-grow: 1;
`;

const DateText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 10px;
`;

// BlogPostCard component
export function BlogPostCard({ slug, title, excerpt, image, date }) {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <Card to={`/blog/${slug}`}>
      <ImagePlaceholder image={image} />
      <ContentWrapper>
        <DateText>{formattedDate}</DateText>
        <CardTitle>{title}</CardTitle>
        <Excerpt>{excerpt}</Excerpt>
      </ContentWrapper>
    </Card>
  );
}

// Styled components for BlogPost
const BlogPostContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const BlogPostTitle = styled.h1`
  font-size: 32px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 20px;
`;

const MarkdownContent = styled(ReactMarkdown)`
  font-size: 18px;
  line-height: 1.6;
  color: ${props => props.theme.colors.text};

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.primary};
    margin-top: 24px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  ul, ol {
    margin-bottom: 16px;
    padding-left: 24px;
  }

  code {
    background-color: ${props => props.theme.colors.background};
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: ${props => props.theme.colors.background};
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
  }

  blockquote {
    border-left: 4px solid ${props => props.theme.colors.accent};
    padding-left: 16px;
    font-style: italic;
  }
`;

// BlogPost component
export function BlogPost() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const post = blogPosts.find(post => post.slug === slug);

  useEffect(() => {
    if (post) {
      fetch(`/blogPosts/${post.slug}.md`)
        .then(response => response.text())
        .then(text => setContent(text))
        .catch(error => console.error('Error loading blog post content:', error));
    }
  }, [post]);

  if (!post) {
    return <div>Blog post not found</div>;
  }

  const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <BlogPostContainer>
      <DateText>{formattedDate}</DateText>
      <BlogPostTitle>{post.title}</BlogPostTitle>
      <MarkdownContent remarkPlugins={[remarkGfm]}>{content}</MarkdownContent>
    </BlogPostContainer>
  );
}
