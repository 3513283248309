import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import Header from './components/Header';
import Home from './components/Home';
import { BlogPost } from './components/BlogPost';
import BlogOverview from './components/BlogOverview';
import CV from './components/CV';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


const AppContainer = styled.div`
  font-family: ${props => props.theme.fonts.main};
  font-weight: ${props => props.theme.fontWeights.normal};
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContainer>
          <ScrollToTop />
          <Header />
          <MainContent>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<BlogOverview />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/cv" element={<CV />} />
            </Routes>
          </MainContent>
          <Footer />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
