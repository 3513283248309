import React from 'react';
import styled from 'styled-components';

const CVContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileInfo = styled.div`
  text-align: right;
`;

const Name = styled.h1`
  font-size: 32px;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 10px;
`;

const Contact = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${props => props.theme.colors.primary};
`;

const BlockTitle = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.secondary};
  margin-top: 25px;
  margin-bottom: 4px;
  margin-left: -10px;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.text};
`;

const BulletList = styled.ul`
  margin: 0;
  padding-left: 20px;
  list-style-type: none;
`;

const BulletItem = styled.li`
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.text};
  position: relative;

  &:before {
    content: "•";
    position: absolute;
    left: -15px;
  }
`;

const ExperienceItem = styled.div`
  margin-bottom: 30px;
`;

const JobHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
`;

const JobTitle = styled.h4`
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  font-weight: bold;
  margin: 0;
`;

const DateRange = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  font-style: italic;
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const Skill = styled.span`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
`;

const CompanySection = styled.div`
  margin-bottom: 40px;
`;

const CompanyName = styled.h3`
  font-size: 24px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 20px;
  padding-bottom: 5px;
`;

const JobContent = styled.div`
  margin-left: 20px;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 15px;
`;

const ListItem = styled.li`
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 2px;
  color: ${props => props.theme.colors.text};
`;

const ToolStackParagraph = styled.p`
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.text};
`;

function CV() {
  const topSkills = [
    "Site Reliability Engineering",
    "DevOps",
    "Kubernetes",
    "AWS",
    "Cloud Infrastructure",
    "Automation",
    "CI/CD",
    "Terraform",
    "Go",
    "Python",
    "Monitoring & Observability",
    "Performance Optimization",
    "Scalability",
    "Linux",
  ];

  return (
    <CVContainer>
      <ProfileSection>
        <ProfileImage src="/images/profile.png" alt="Christian Beneke" />
        <ProfileInfo>
          <Name>Christian Beneke</Name>
          <Title>Site Reliability Engineer</Title>
          <Contact>Berlin, Germany</Contact>
        </ProfileInfo>
      </ProfileSection>

      <Section>
        <SectionTitle>Profile</SectionTitle>
        <Paragraph>
          I am a highly skilled Site Reliability Engineer with extensive experience in ensuring 
          the integrity, performance, reliability, and cost-effectiveness of cloud-based 
          infrastructure. With more than 10 years of experience, I became a technical leader, 
          guiding through the hyper-growth phase of Trade Republic, a financial industry 
          services company. I am committed to delivering high-quality service, have a strong 
          understanding of industry best practices, and apply a product mindset – knowing 
          when to cut short and when to optimize.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Top Skills</SectionTitle>
        <SkillsContainer>
          {topSkills.map((skill, index) => (
            <Skill key={index}>{skill}</Skill>
          ))}
        </SkillsContainer>
      </Section>

      <Section>
        <SectionTitle>Employment History</SectionTitle>
        
        <CompanySection>
          <CompanyName>Trade Republic Bank GmbH</CompanyName>
          
          <ExperienceItem>
            <JobHeader>
              <JobTitle>Staff Site Reliability Engineer</JobTitle>
              <DateRange>Aug 2022 – Present</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Lead critical projects in Platform Engineering, focusing on system reliability, performance, and cost-effectiveness.
              </Paragraph>
              <BulletList>
                <BulletItem>Execute projects to enhance technical landscape and reduce costs</BulletItem>
                <BulletItem>Improve MTTR and engineering velocity</BulletItem>
                <BulletItem>Provide technical leadership and architectural guidance</BulletItem>
                <BulletItem>Optimize AWS infrastructure, including Kubernetes clusters</BulletItem>
                <BulletItem>Develop strategic visions for foundational work</BulletItem>
                <BulletItem>Design solutions for complex, long-term challenges</BulletItem>
                <BulletItem>Oversee engineering processes and documentation</BulletItem>
                <BulletItem>Participate in 24/7 on-call rotation</BulletItem>
              </BulletList>

              <BlockTitle>Key Achievements</BlockTitle>
              <List>
                <ListItem>Reduced monthly cost of Kubernetes Clusters by ~30% while increasing stability and reducing operational overhead by  migrating from KOPS self-managed cluster to
                use-case optimised EKS clusters in an active-active manner.</ListItem>
                <ListItem>Reduced resource consumption and p99 latency of an extremely low-latency system by ~40% by configuring a shared communication resource, leveraging cloud-native approaches
                on a legacy system.</ListItem>
              </List>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>AWS, Kubernetes, Terraform, RDS, Kafka, RabbitMQ, Cassandra, ArgoCD, Grafana LGTM, Datadog, Ansible, Aeron</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Java/Kotlin, Bash</Paragraph>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Engineering Manager</JobTitle>
              <DateRange>Nov 2021 – Aug 2022</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Led and developed Site Reliability Engineering teams, focusing on growth, performance, and alignment with company objectives.
              </Paragraph>
              <BulletList>
                <BulletItem>Managed three SRE teams, ensuring efficient operations</BulletItem>
                <BulletItem>Drove the hiring process and grew the team from 8 to 16 members</BulletItem>
                <BulletItem>Developed growth and personal development plans for direct reports</BulletItem>
                <BulletItem>Assessed infrastructure requirements and created project roadmaps</BulletItem>
                <BulletItem>Coordinated with team members and management for task completion</BulletItem>
                <BulletItem>Spearheaded the adoption of automation and cloud-native core concepts</BulletItem>
                <BulletItem>Educated developers on cloud-native and Kubernetes-specific concepts</BulletItem>
                <BulletItem>Participated in the 24/7 on-call rotation</BulletItem>
              </BulletList>

              <BlockTitle>Key Achievements</BlockTitle>
              <List>
                <ListItem>Grew the SRE team from 8 to 16 members, while significantly expanding capabilities</ListItem>
                <ListItem>Implemented comprehensive growth and development plans for all team members</ListItem>
              </List>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Tech Lead Infrastructure</JobTitle>
              <DateRange>Sept 2020 – Oct 2021</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Headed the IT Infrastructure teams, overseeing both SRE and Corporate IT functions.
              </Paragraph>
              <BulletList>
                <BulletItem>Led and managed the SRE and Corporate IT teams</BulletItem>
                <BulletItem>Drove the hiring process and grew the teams from 1 to over 15 members</BulletItem>
                <BulletItem>Analyzed infrastructure requirements and developed project roadmaps</BulletItem>
                <BulletItem>Administered, automated, and optimized AWS-based infrastructure and Kubernetes clusters</BulletItem>
                <BulletItem>Implemented and promoted the adoption of automation and cloud-native concepts</BulletItem>
                <BulletItem>Managed the lifecycle of various applications in production and testing environments</BulletItem>
                <BulletItem>Educated developers on cloud-native and Kubernetes-specific concepts</BulletItem>
                <BulletItem>Actively participated in the 24/7 on-call rotation</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>AWS, Kubernetes, Terraform, RDS, RabbitMQ, Cassandra, ArgoCD, Prometheus-Stack</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Bash</Paragraph>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Site Reliability Engineer</JobTitle>
              <DateRange>Oct 2019 – Sept 2020</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Part of the team responsible for building and maintaining Trade Republic's server infrastructure.
              </Paragraph>
              <BulletList>
                <BulletItem>Administered, automated, and optimized AWS-based infrastructure and Kubernetes clusters</BulletItem>
                <BulletItem>Implemented and promoted the adoption of automation and cloud-native concepts</BulletItem>
                <BulletItem>Managed the lifecycle of various applications across production and testing environments</BulletItem>
                <BulletItem>Educated developers on cloud-native and Kubernetes-specific concepts</BulletItem>
                <BulletItem>Participated in the 24/7 on-call rotation</BulletItem>
                <BulletItem>Contributed to the hiring process for the SRE team</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>AWS, Kubernetes, Terraform, RDS, RabbitMQ, Cassandra, Prometheus-Stack</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Bash</Paragraph>
            </JobContent>
          </ExperienceItem>
        </CompanySection>

        <CompanySection>
          <CompanyName>SysEleven GmbH</CompanyName>
          
          <ExperienceItem>
            <JobHeader>
              <JobTitle>Site Reliability Engineer - Kubernetes</JobTitle>
              <DateRange>May 2018 – Sept 2019</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                One of the initial members of the SysEleven MetaKube team, played a crucial role in developing and maintaining our Kubernetes as a service platform.
              </Paragraph>
              <BulletList>
                <BulletItem>Designed and implemented a robust Kubernetes as a service platform</BulletItem>
                <BulletItem>Developed best practices for customer cluster design and management</BulletItem>
                <BulletItem>Created and implemented a comprehensive monitoring solution</BulletItem>
                <BulletItem>Managed, automated, and optimized both internal and customer Kubernetes clusters</BulletItem>
                <BulletItem>Conceptualized and maintained various add-ons for popular in-cluster tools</BulletItem>
                <BulletItem>Participated in the 24/7 on-call rotation</BulletItem>
                <BulletItem>Conducted internal and external workshops and talks</BulletItem>
                <BulletItem>Developed software solutions in Go and Python</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>Kubernetes, OpenStack, AWS, Prometheus-Stack, Terraform, Helm, Velero, HashiCorp Vault</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Bash, NodeJS/Typescript</Paragraph>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Systems Engineer Linux</JobTitle>
              <DateRange>Nov 2017 – Apr 2018</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Expanded on previous role with additional responsibilities.
              </Paragraph>
              <BulletList>
                <BulletItem>Provided support for extensive changes in customer systems</BulletItem>
                <BulletItem>Conceptualized and set up customer-tailored hosting solutions</BulletItem>
                <BulletItem>Updated existing and developed new Puppet modules for company-wide use</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>Linux (Ubuntu, Gentoo, CentOS), Nginx, Apache, ELK, Puppet, MySQL (Percona), Redis/Memcached, Icinga2, Zabbix, Tomcat, Cassandra</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Bash, NodeJS/Typescript</Paragraph>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Junior System Engineer Linux – Working Student</JobTitle>
              <DateRange>Nov 2016 – Oct 2017</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Junior System Engineer in the service operations center.
              </Paragraph>
              <BulletList>
                <BulletItem>Administered, automated, and optimized Linux-based server systems</BulletItem>
                <BulletItem>Monitored infrastructure and conducted regular trend data analysis</BulletItem>
                <BulletItem>Provided incident response and change support for customers</BulletItem>
                <BulletItem>Managed the lifecycle of infrastructure components</BulletItem>
                <BulletItem>Served as a direct technical contact for customers</BulletItem>
                <BulletItem>Documented internal processes and customer solutions</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>Linux (Ubuntu, Gentoo, CentOS), Nginx, Apache, ELK, Puppet, MySQL (Percona), Redis/Memcached, Icinga2, Zabbix, Tomcat</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Go, Python, Bash, NodeJS/Typescript</Paragraph>
            </JobContent>
          </ExperienceItem>
        </CompanySection>

        <CompanySection>
          <CompanyName>Technical University Berlin</CompanyName>
          
          <ExperienceItem>
            <JobHeader>
              <JobTitle>Working Student</JobTitle>
              <DateRange>Apr 2016 – Oct 2016</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Contributed to various IT support and development tasks at the central IT department (tubIT).
              </Paragraph>
              <BulletList>
                <BulletItem>Provided helpdesk support for IT-related issues</BulletItem>
                <BulletItem>Developed the network management frontend for the new self-service portal</BulletItem>
                <BulletItem>Contributed to the development of the new identity management backend</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>AngularJS</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Javascript, Java</Paragraph>
            </JobContent>
          </ExperienceItem>

          <ExperienceItem>
            <JobHeader>
              <JobTitle>Working Student</JobTitle>
              <DateRange>Nov 2012 – Mar 2016</DateRange>
            </JobHeader>
            <JobContent>
              <Paragraph>
                Working student at the educational computer center Unix-Pool.
              </Paragraph>
              <BulletList>
                <BulletItem>Managed and maintained a fleet of 250 Linux clients and 8 servers</BulletItem>
                <BulletItem>Designed, implemented, and managed the backup solution</BulletItem>
                <BulletItem>Developed and maintained the central account management system</BulletItem>
                <BulletItem>Provided helpdesk support to students using the systems</BulletItem>
              </BulletList>

              <BlockTitle>Tool Stack</BlockTitle>
              <ToolStackParagraph>Debian, LDAP, Bacula/Bareos</ToolStackParagraph>

              <BlockTitle>Languages</BlockTitle>
              <Paragraph>Bash, Python</Paragraph>
            </JobContent>
          </ExperienceItem>
        </CompanySection>
      </Section>

      <Section>
        <SectionTitle>Education</SectionTitle>
        <ExperienceItem>
          <JobHeader>
            <JobTitle>Bachelor of Science (B.Sc.), Mathematics</JobTitle>
            <DateRange>Jan 2011 – Nov 2017</DateRange>
          </JobHeader>
          <Paragraph>
            Minor: Computer Science<br />
            Specialisation: Optimization and discrete Mathematics
          </Paragraph>
        </ExperienceItem>
      </Section>
    </CVContainer>
  );
}

export default CV;